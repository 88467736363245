import React from 'react';

import { ENTITLEMENT_TIERS } from 'components/entitlements/constants';

import TeamsFlyoutMenu, { TeamsMobileMenu } from './TeamsFlyoutMenu';

export const ANALYTICS_SUBMENU = [
  {
    name: 'Analytics',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'MTTX Analytics',
    href: '/analytics/mttx_analytics/services',
    keepQuery: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Alerts',
    href: '/analytics/alerts',
    keepQuery: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    flag: 'signals',
  },
  {
    name: 'On-Call Hours',
    href: '/analytics/shift_hours',
    requiredRoles: ['collaborator', 'member', 'owner'],
    flag: 'signals',
  },
  {
    name: 'Incidents and Impact',
    href: '/analytics/incidents_impacts',
    keepQuery: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Resources and Tasks',
    href: '/analytics/resources_tasks',
    keepQuery: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
];

export const INCIDENTS_SUBMENU = [
  {
    name: 'Incidents',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'All Incidents',
    href: '/incidents',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    exactMatch: true,
  },
  {
    name: 'Tasks',
    href: '/incidents/tasks',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Follow Ups',
    href: '/incidents/follow_ups',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Scheduled Maintenances',
    href: '/incidents/maintenances',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
];

export const SIGNALS_SUBMENU = [
  {
    name: 'Signals',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Alerts',
    href: '/signals/alerts',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Event Logs',
    href: '/signals/logs',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Event Sources',
    href: '/signals/sources',
    requiredRoles: ['member', 'owner'],
  },
  {
    name: 'On-Call Schedules',
    href: '/signals/on_call_schedules',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Alert Grouping',
    href: '/signals/alert_grouping',
    requiredRoles: ['member', 'owner'],
    flag: 'alertGrouping',
    isNew: true,
  },
  {
    name: 'Webhook Targets',
    href: '/signals/webhook_targets',
    requiredRoles: ['member', 'owner'],
  },
];

export const CATALOG_SUBMENU = [
  {
    name: 'Catalog',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Services',
    href: '/catalog/services',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    exactMatch: true,
  },
  {
    name: 'Functionalities',
    href: '/catalog/functionalities',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },

  {
    name: 'Environments',
    href: '/catalog/environments',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Readiness Checklists',
    href: '/catalog/checklists',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Change events',
    href: '/catalog/change_events',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Catalog settings',
    href: '/catalog/services/catalog_settings',
    requiredRoles: ['member', 'owner'],
  },
];

export const SETTINGS_SUBMENU = [
  {
    name: 'General',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Organization',
    href: '/settings',
    requiredRoles: ['owner'],
    exactMatch: true,
  },
  {
    name: '✨ AI',
    href: '/settings/ai',
    requiredRoles: ['owner'],
    flag: 'integrationsSlackAiSummary',
  },
  {
    name: 'Users',
    href: '/settings/users',
    requiredRoles: ['owner'],
  },
  {
    name: 'Roles and Permissions',
    href: '/settings/rbac_roles',
    requiredRoles: ['owner'],
    flag: 'fineGrainedPermissionsUi',
  },
  {
    name: 'Teams',
    href: '/settings/teams',
    hideFlag: 'signals',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Single sign on',
    href: '/settings/sso/edit',
    requiredRoles: ['owner'],
  },
  {
    name: 'Billing and Usage',
    href: '/settings/billing',
    requiredRoles: ['owner'],
  },
  {
    name: 'Incidents',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Incident settings',
    href: '/settings/incident_fields',
    requiredRoles: ['member', 'owner'],
  },
  {
    name: 'Incident milestones',
    href: '/settings/incident_milestones',
    requiredRoles: ['member', 'owner'],
    flag: 'customMilestones',
  },
  {
    name: 'Incident types',
    href: '/settings/incident_types',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Roles',
    href: '/settings/roles',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Audiences',
    href: '/settings/audiences',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    isNew: true,
    flag: 'incidentAudiences',
  },
  {
    name: 'Severities',
    href: '/settings/severities_index',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Priorities',
    href: '/settings/priorities',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Conditions',
    href: '/settings/conditions',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Severities matrix',
    href: '/settings/severities_matrix',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Status templates',
    href: '/settings/status_update_templates',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Task lists',
    href: '/settings/task_lists',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Ticketing settings',
    href: '/settings/tickets',
    requiredRoles: ['owner'],
  },
  {
    name: 'Retrospective configuration',
    href: '/settings/retrospectives/edit',
    requiredRoles: ['member', 'owner'],
    hideFlag: 'retroTemplates',
  },
  {
    name: 'Retrospective templates',
    href: '/settings/retrospectives/templates',
    requiredRoles: ['member', 'owner'],
    flag: 'retroTemplates',
    isNew: true,
  },
  {
    name: 'Integrations',
    header: true,
    requiredRoles: ['owner'],
  },
  {
    name: 'Integration List',
    iconName: 'integrations',
    href: '/settings/integrations',
    requiredRoles: ['owner'],
    exclusions: ['/settings/integrations/webhooks'],
  },
  {
    name: 'API Keys',
    href: '/settings/api_keys',
    requiredRoles: ['owner'],
  },
  {
    name: 'Webhooks',
    href: '/settings/integrations/webhooks',
    requiredRoles: ['owner'],
  },
  {
    name: 'Secrets',
    href: '/settings/secrets',
    requiredRoles: ['owner'],
  },
  {
    name: 'Audit events',
    href: '/settings/audit_events',
    requiredRoles: ['owner'],
    flag: 'auditEvents',
    isNew: true,
  },
];

export const PROFILE_SUBMENU = [
  {
    name: 'User Settings',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Profile',
    href: '/account/edit',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Password & Security',
    href: '/account/password_security',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'Organizations',
    header: true,
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
];

export const USER_SUBMENU = [
  {
    name: 'Profile',
    href: '/account/edit',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
  },
  {
    name: 'API documentation',
    href: 'https://developers.firehydrant.com/',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    externalLink: true,
  },
  {
    name: 'Help center',
    href: 'https://firehydrant.com/docs',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    externalLink: true,
  },
  {
    name: 'Changelog',
    href: 'https://firehydrant.com/changelog/',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    externalLink: true,
  },
  {
    name: 'Sign out',
    href: '/sessions',
    requiredRoles: ['viewer', 'collaborator', 'member', 'owner'],
    extras: {
      href: '/sessions',
      rel: 'nofollow',
      boxShadow: 'none !important',
      textDecoration: 'none !important',
      'data-method': 'delete',
    },
  },
];

export const HORIZONTAL_NAV_STRUCTURE = [
  {
    route: '/overview',
    displayName: 'Home',
    hideFlag: 'signals',
  },
  {
    route: '/signals',
    displayName: 'Signals',
    subMenuItems: SIGNALS_SUBMENU,
    flag: 'signals',
  },
  {
    route: '/incidents',
    displayName: 'Incidents',
    subMenuItems: INCIDENTS_SUBMENU,
  },
  {
    route: '/runbooks',
    displayName: 'Runbooks',
  },
  {
    displayName: 'Status pages',
    route: '/status_pages',
  },
  {
    route: '/catalog',
    displayName: 'Catalog',
    subMenuItems: CATALOG_SUBMENU,
  },
  {
    route: '/analytics',
    displayName: 'Analytics',
    subMenuItems: ANALYTICS_SUBMENU,
  },
  {
    route: '/settings/teams',
    displayName: 'Teams',
    flyOutMenu: (onClose) => <TeamsFlyoutMenu onClose={onClose} />,
    dynamicMobileSubMenu: () => <TeamsMobileMenu />,
    flag: 'signals',
  },
  {
    displayName: 'Settings',
    route: '/settings',
    subMenuItems: SETTINGS_SUBMENU,
    hideFlag: 'signals',
  },
  {
    displayName: 'Settings',
    route: '/settings',
    subMenuItems: SETTINGS_SUBMENU,
    flag: 'signals',
    isMobileOnly: true,
  },
  {
    displayName: 'User',
    isMobileOnly: true,
    navIcon: true,
    subMenuItems: USER_SUBMENU,
  },
];

// This is being used for Breadcrumbs and helmet text
export default (internalTools, copySupportInfo = () => {}) => [
  [
    {
      name: 'Home',
      href: '/overview',
      ownerOnly: false,
      iconName: 'home',
    },
    {
      name: 'Signals',
      href: '/signals',
      ownerOnly: false,
      iconName: 'incidents',
      flag: 'signals',
    },
    {
      name: 'Incidents',
      href: '/incidents',
      ownerOnly: false,
      iconName: 'incidents',
    },
    {
      name: 'Incident response',
      ownerOnly: false,
      iconName: 'incidentResponse',
      subMenu: [
        {
          name: 'Scheduled maintenances',
          href: '/scheduled_maintenances',
          ownerOnly: false,
        },
        {
          name: 'Task Lists',
          href: '/task_lists',
          ownerOnly: false,
          badge: true,
        },
        {
          name: 'Teams',
          href: '/teams',
          ownerOnly: false,
        },
        {
          name: 'Roles',
          href: '/incident_roles',
          ownerOnly: false,
        },
        {
          name: 'Severities',
          href: '/organizations/severities',
          ownerOnly: false,
        },
        {
          name: 'Retrospective configuration',
          href: '/organizations/retrospectives/configure',
          ownerOnly: true,
          hideFlag: 'retroTemplates',
        },
        {
          name: 'Retrospective templates',
          href: '/settings/retrospectives/templates',
          requiredRoles: ['member', 'owner'],
          flag: 'retroTemplates',
          ownerOnly: true,
        },
        {
          name: 'Incident types',
          href: '/incident_types',
          ownerOnly: false,
        },
        {
          name: 'Ticketing settings',
          href: '/ticketing_settings',
          ownerOnly: true,
        },
      ],
    },
    {
      name: 'Runbooks',
      href: '/runbooks',
      ownerOnly: false,
      iconName: 'runbooks',
    },
  ],
  [
    {
      name: 'Service catalog',
      ownerOnly: false,
      iconName: 'infrastructure',
      subMenu: [
        {
          name: 'Services',
          href: '/services',
          ownerOnly: false,
        },
        {
          name: 'Functionalities',
          href: '/functionalities',
          ownerOnly: false,
        },

        {
          name: 'Environments',
          href: '/environments',
          ownerOnly: false,
        },
        {
          name: 'Readiness Checklists',
          href: '/checklists',
          ownerOnly: false,
        },
        {
          name: 'Change events',
          href: '/change_events',
          ownerOnly: false,
        },
      ],
    },
    {
      name: 'Status pages',
      iconName: 'statusPages',
      href: '/organizations/nunc',
      ownerOnly: true,
    },
    {
      name: 'Analytics',
      version: 1,
      iconName: 'analytics',
      href: '/analytics/basic',
      flag: 'fdnsAnalyticsRedesignEndpoints',
      ownerOnly: false,
    },
    {
      name: 'Analytics',
      version: 2,
      iconName: 'analytics',
      ownerOnly: false,
      flag: 'fdnsAnalyticsRedesignEndpoints',
      badge: true,
      subMenu: [
        {
          name: 'Incidents and Impact',
          href: '/analytics/incidents_and_impact',
          ownerOnly: false,
          tierRestriction: [ENTITLEMENT_TIERS.enterprise],
        },
        {
          name: 'Impact Statistics',
          href: '/analytics/mttx_analytics',
          ownerOnly: false,
          tierRestriction: [ENTITLEMENT_TIERS.enterprise],
          isBeta: true,
        },
        {
          name: 'Resources and Tasks',
          href: '/analytics/resources_and_tasks',
          ownerOnly: false,
          tierRestriction: [ENTITLEMENT_TIERS.enterprise],
        },
        {
          name: 'Data Export',
          href: '/analytics/data_export',
          ownerOnly: false,
          tierRestriction: [ENTITLEMENT_TIERS.enterprise],
        },
        {
          name: 'Basic Analytics',
          iconName: 'analytics',
          href: '/analytics/basic',
          ownerOnly: false,
        },
      ],
    },
    {
      name: 'Integrations',
      iconName: 'integrations',
      href: '/organizations/integrations',
      ownerOnly: true,
    },
  ],
  [
    {
      name: 'Help',
      ownerOnly: false,
      iconName: 'help',
      subMenu: [
        {
          name: 'API documentation',
          href: 'https://developers.firehydrant.com',
          isExternalLink: true,
          ownerOnly: false,
          rel: 'noopener',
          target: '_blank',
        },
        {
          name: 'Help center',
          href: 'https://firehydrant.com/docs',
          isExternalLink: true,
          ownerOnly: false,
          rel: 'noopener',
          target: '_blank',
        },
        {
          name: 'Support info',
          onClick: copySupportInfo,
        },
        {
          name: 'Changelog',
          href: 'https://firehydrant.com/changelog/',
          isExternalLink: true,
          ownerOnly: false,
          rel: 'noopener',
          target: '_blank',
        },
      ],
    },
    {
      name: 'Organization',
      ownerOnly: true,
      iconName: 'organization',
      subMenu: [
        {
          name: 'Account overview',
          href: '/organizations/settings',
          ownerOnly: false,
        },
        {
          name: 'Users',
          href: '/organizations/members',
          ownerOnly: false,
        },
        {
          name: 'Bot users',
          href: '/organizations/bots',
          ownerOnly: true,
        },
        {
          name: 'Secrets',
          href: '/organizations/secrets',
          ownerOnly: true,
        },
        {
          name: 'Single sign on',
          href: '/organizations/sso/settings/edit',
          ownerOnly: false,
        },
      ],
    },
    {
      name: 'User',
      ownerOnly: false,
      iconName: 'user',
      subMenu: [
        {
          name: 'My account',
          href: '/account/edit',
          ownerOnly: false,
          hideStaffSession: true,
        },
        {
          name: 'Notification settings',
          href: '/account_notifications/edit',
          ownerOnly: false,
          hideStaffSession: true,
        },
        {
          name: 'Sign out',
          href: '/sessions',
          rel: 'nofollow',
          'data-method': 'delete',
          ownerOnly: false,
        },
      ],
    },
  ],
];
