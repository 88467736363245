import React, { Suspense, useEffect } from 'react';

import loadable from '@loadable/component';
import { useUserContext } from 'contexts/UserContext/UserContextProvider';
import RedirectWithParameters from 'helpers/RedirectWithParameters';
import { asyncWithLDProvider, useFlags } from 'launchdarkly-react-client-sdk';
import * as ReactDOMClient from 'react-dom/client';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';

// Register global Vite chunk error handler
window.addEventListener('vite:preloadError', (event) => {
  console.warn('Failed to load chunk:', event.payload);

  // Prevent the error from propagating
  event.preventDefault();

  // Log for the user
  console.info('Reloading page to recover from chunk loading error...');

  // Reload the page after a short delay
  setTimeout(() => {
    window.location.reload();
  }, 500);
});

import AccountRoutes from 'components/account/AccountRoutes';
import AccountRoutesV2 from 'components/account_v2/AccountRoutes';
import { useAuthorization } from 'components/common/Authorization/AuthHook';
import Loading from 'components/common/Loading/Loading';
import FullPageLayout from 'components/layout/FullPageLayout';
import { SessionReplayWrapper } from 'components/layout/SessionReplayWrapper';
import SessionWrapper from 'components/layout/SessionWrapper';

import SPAWrapper from './helpers/SPAWrapper';

const RuleCreateForm = loadable(
  () => import('components/teamsV2/components/SignalsTeamsShow/RuleCreateForm'),
);

const EscalationPolicyForm = loadable(
  () =>
    import(
      'components/teamsV2/components/SignalsTeamsShow/EscalationPolicyForm/EscalationPolicyForm'
    ),
);

const AlertGroupingForm = loadable(
  () => import('components/signals/components/AlertGrouping/AlertGroupingForm'),
);

const FiredUpDashboard = loadable(
  () =>
    import(
      'components/onboarding/components/Dashboard/FiredUpDashboard/FiredUpDashboard.jsx'
    ),
);

const LoginPage = loadable(
  () => import('components/login/components/LoginPage'),
);
const SignalsRegistrationNew = loadable(
  () => import('components/signals/registration/SignalsRegistrationNew'),
);
const SignalsRegistrationComplete = loadable(
  () => import('components/signals/registration/SignalsRegistrationComplete'),
);
const SignalsOnboarding = loadable(
  () => import('components/signals/registration/SignalsOnboarding'),
);
const SignalsOnboardingAlert = loadable(
  () => import('components/signals/registration/SignalsOnboardingAlert'),
);
const ProtectedEntitlementsRoute = loadable(
  () => import('components/entitlements/components/ProtectedEntitlementsRoute'),
);
const ProtectedImportsRoute = loadable(
  () => import('components/entitlements/components/ProtectedImportsRoute'),
);
const ProtectedRunbookEditRoute = loadable(
  () =>
    import('components/runbooksV2/components/shared/ProtectedRunbookEditRoute'),
);
const IncidentRoutes = loadable(
  () => import('components/incidentsIndex/IncidentRoutes'),
);
const AnalyticsIndex = loadable(
  () => import('components/analyticsV2/components/AnalyticsIndex'),
);
const ImportResults = loadable(
  () => import('components/imports/components/ImportResults'),
);
const CCV2Redirect = loadable(
  () => import('components/incidentV2/CCV2Redirect'),
);
const MultiOrgRoutes = loadable(
  () => import('components/routes/MultiOrgRoutes'),
);
const NuncNewForm = loadable(() => import('components/nunc/NuncNewForm'));
const NuncConfigForm = loadable(() => import('components/nunc/NuncConfigForm'));

const OnboardingV3 = loadable(
  () =>
    import('components/onboarding/components/OnboardingV3/OnboardingV3.jsx'),
);
const RetrospectiveRoutes = loadable(
  () => import('components/retrospectiveV2/RetrospectiveRoutes'),
);
const TeamsIndex = loadable(
  () => import('components/teamsV2/components/Index'),
);
const CatalogIndex = loadable(
  () => import('components/catalog/components/CatalogIndex'),
);
const EnvironmentsIndexV2 = loadable(
  () => import('components/environments/components/IndexV2'),
);
const IntegrationsSelection = loadable(
  () => import('components/imports/components/IntegrationsSelection'),
);
const LinkAndImport = loadable(
  () => import('components/imports/components/LinkAndImport'),
);
const CCV1Redirect = loadable(
  () => import('components/incidentV2/CCV1Redirect'),
);
const NuncSubscribers = loadable(
  () => import('components/nunc_subscribers/NuncSubscribers'),
);
const NuncIndex = loadable(() => import('components/nuncs/Index'));
const SettingsIndex = loadable(
  () => import('components/settings/components/SettingsIndex'),
);
const SignalsRoutes = loadable(() => import('components/signals/SignalsIndex'));

const RunbooksIndexV2 = loadable(
  () => import('components/runbooks/components/RunbooksIndexV2'),
);
const RunbookFormWrapper = loadable(
  () => import('components/runbooksV2/components/RunbookFormWrapper'),
);
const RunbooksShow = loadable(
  () => import('components/runbooksV2/components/RunbookShow'),
);
const TeamsV2Index = loadable(
  () => import('components/teamsV2/components/Index'),
);
const SignalsTeamShow = loadable(
  () =>
    import('components/teamsV2/components/SignalsTeamsShow/SignalsTeamShow'),
);
const TeamFormV2 = loadable(
  () => import('components/teamsV2/components/TeamsEdit/TeamFormV2'),
);
const ScheduleCreateForm = loadable(
  () =>
    import(
      'components/teamsV2/components/SignalsTeamsShow/OnCallSchedules/ScheduleCreateForm'
    ),
);
const ScheduleUpdateForm = loadable(
  () =>
    import(
      'components/teamsV2/components/SignalsTeamsShow/OnCallSchedules/ScheduleUpdateForm'
    ),
);

const RetrospectiveTemplateView = loadable(
  () =>
    import(
      'components/retrospectives/templates/views/RetrospectiveTemplateView'
    ),
);

const RetrospectiveTemplateGuide = loadable(
  () =>
    import(
      'components/retrospectives/templates/views/RetrospectiveTemplateView/RetroTemplateGuide'
    ),
);
const ScheduledMaintenanceForm = loadable(
  () =>
    import(
      'components/scheduled_maintenances/components/ScheduledMaintenanceForm'
    ),
);

const Page404 = loadable(() => import('components/common/Page404'));
const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    if (!hash) {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
};

const LayoutRoutes = () => {
  const { signals, profilePageRedesign, retroTemplates, alertGrouping } =
    useFlags();
  const {
    isUserOwner,
    isUserStaffSession,
    isLoading: isCurrentUserLoading,
    account,
  } = useUserContext();
  const isOwnerOrMember = useAuthorization(['owner', 'member']);

  const accountRoute = profilePageRedesign ? (
    <AccountRoutesV2 />
  ) : (
    <AccountRoutes />
  );

  return (
    <Routes>
      <Route element={<CCV2Redirect />} path="v2/incidents/:incidentId/*" />
      <Route
        path="incidents/:incidentId/retrospective/*"
        element={<RetrospectiveRoutes />}
      />
      <Route
        path="runbooks/new"
        element={
          <ProtectedEntitlementsRoute
            element={RunbookFormWrapper}
            entitlementName="runbooks"
            path="runbooks/new"
            redirectPath="runbooks"
          />
        }
      />
      <Route
        path="runbooks/new/:template"
        element={
          <ProtectedEntitlementsRoute
            element={RunbookFormWrapper}
            entitlementName="runbooks"
            path="runbooks/new/:template"
            redirectPath="runbooks"
          />
        }
      />
      <Route
        path="runbooks/:copyId/copy"
        element={
          <ProtectedEntitlementsRoute
            element={RunbookFormWrapper}
            entitlementName="runbooks"
            path="runbooks/:copyId/copy"
            redirectPath="runbooks"
          />
        }
      />
      <Route element={<RunbooksShow />} path="runbooks/:id" />
      {isOwnerOrMember && (
        <Route
          path="runbooks/:id/edit"
          element={<ProtectedRunbookEditRoute path="runbooks/:id/edit" />}
        />
      )}
      <Route
        path="organizations/invitations/new"
        element={<Navigate replace to="/settings/invitations/new" />}
      />
      {!isUserStaffSession && (
        <Route
          element={<Navigate replace to="/account/organization_settings" />}
          path="account_notifications/edit"
        />
      )}
      <Route
        element={<ImportResults />}
        path="imports/:infrastructureType/results/:id"
      />
      <Route
        path="imports/:infrastructureType"
        element={
          <ProtectedImportsRoute
            element={IntegrationsSelection}
            path="imports/:infrastructureType"
          />
        }
      />
      <Route
        path="imports/:infrastructureType/start/:connectionType/:connectionId?"
        element={
          <ProtectedImportsRoute
            element={LinkAndImport}
            entitlementName="service_limit"
            path="imports/:infrastructureType/start/:connectionType/:connectionId?"
          />
        }
      />
      <Route path="/">
        {signals && (
          <>
            <Route element={<SignalsRoutes />} path="signals/*" />
            <Route element={<TeamsV2Index />} path="settings/teams" />
            <Route element={<SignalsTeamShow />} path="settings/teams/:id" />
            {isOwnerOrMember && (
              <>
                <Route element={<TeamFormV2 />} path="settings/teams/new" />
                <Route
                  element={<TeamFormV2 />}
                  path="settings/teams/:id/edit"
                />
                <Route
                  path="settings/teams/:id/schedules/new"
                  element={<ScheduleCreateForm />}
                />
                <Route
                  path="settings/teams/:id/schedules/:scheduleId/edit"
                  element={<ScheduleUpdateForm />}
                />
                <Route
                  path="settings/teams/:id/rules/new"
                  element={<RuleCreateForm />}
                />
                <Route
                  path="settings/teams/:id/rules/:ruleId/edit"
                  element={<RuleCreateForm />}
                />
                <Route
                  path="settings/teams/:id/escalation_policies/new"
                  element={<EscalationPolicyForm />}
                />
                <Route
                  path="settings/teams/:id/escalation_policies/:policyId/edit"
                  element={<EscalationPolicyForm />}
                />
                {alertGrouping && (
                  <>
                    <Route
                      path="signals/alert_grouping/new"
                      element={<AlertGroupingForm />}
                    />
                    <Route
                      path="signals/alert_grouping/:groupId/edit"
                      element={<AlertGroupingForm />}
                    />
                  </>
                )}
              </>
            )}
            <Route element={<SignalsTeamShow />} path="settings/teams/:id/*" />
          </>
        )}
        <Route element={<SettingsIndex />} path="settings/*" />
        {!isUserStaffSession && (
          <Route element={accountRoute} path="account/*" />
        )}
        <Route
          element={<AnalyticsIndex tier={account.tier} />}
          path="analytics/*"
        />
        <Route element={<CatalogIndex />} path="catalog/*" />
        <Route element={<IncidentRoutes />} path="incidents/*" />
        <Route element={<NuncIndex />} path="status_pages" />
        <Route element={<NuncNewForm />} path="status_pages/new" />
        <Route
          element={<NuncConfigForm />}
          path="status_pages/:statusPageId/edit"
        />
        <Route
          element={<NuncSubscribers />}
          path="status_pages/:id/subscribers"
        />

        <Route
          element={<Navigate replace to="/settings/tickets" />}
          path="ticketing_settings"
        />
        <Route
          element={<Navigate replace to="/settings/general" />}
          path="organizations/settings"
        />
        <Route
          element={<Navigate replace to="/settings/users" />}
          path="organizations/members"
        />
        <Route
          element={<RedirectWithParameters to="/settings/users/:id/edit" />}
          path="/organizations/members/:id/edit"
        />
        <Route
          element={<Navigate replace to="/settings/sso/edit" />}
          path="organizations/ssoedit"
        />
        <Route
          element={<Navigate replace to="/settings/integrations" />}
          path="organizations/integrations"
        />
        <Route
          element={<RedirectWithParameters replace to="/settings/api_keys" />}
          path="organizations/bots"
        />
        <Route
          element={<Navigate replace to="/settings/secrets" />}
          path="organizations/secrets"
        />
        <Route
          element={<Navigate replace to="/settings/api_keys/new" />}
          path="organizations/bots/new"
        />
        <Route
          element={<RedirectWithParameters to="/settings/api_keys/:id/edit" />}
          path="organizations/bots/:id/edit"
        />
        <Route
          element={<Navigate replace to="/settings/incident_types" />}
          path="incident_types"
        />
        <Route
          element={<Navigate replace to="/settings/incident_types/new" />}
          path="incident_types/new"
        />
        <Route
          element={
            <RedirectWithParameters to="/settings/incident_types/:id/edit" />
          }
          path="incident_types/:id/edit"
        />

        <Route
          element={<Navigate replace to="/settings/severities" />}
          path="organizations/severities"
        />

        <Route
          element={<Navigate replace to="/settings/teams" />}
          path="teams"
        />
        <Route
          element={<Navigate replace to="/settings/teams/new" />}
          path="teams/new"
        />
        <Route
          element={<RedirectWithParameters to="/settings/teams/:id/edit" />}
          path="teams/:id/edit"
        />
        <Route
          element={<RedirectWithParameters to="/settings/teams/:id" />}
          path="teams/:id"
        />

        <Route
          element={<Navigate replace to="/settings/roles" />}
          path="incident_roles"
        />
        <Route
          element={<Navigate replace to="/settings/roles/new" />}
          path="incident_roles/new"
        />
        <Route
          element={<RedirectWithParameters to="/settings/roles/:id/edit" />}
          path="incident_roles/:id/edit"
        />

        <Route
          element={<Navigate replace to="/settings/status_update_templates" />}
          path="status_update_templates"
        />
        <Route
          element={
            <Navigate replace to="/settings/status_update_templates/new" />
          }
          path="status_update_templates/new"
        />
        <Route
          element={
            <RedirectWithParameters to="/settings/status_update_templates/:id/edit" />
          }
          path="status_update_templates/:id/edit"
        />

        <Route
          element={<Navigate replace to="/settings/task_lists" />}
          path="task_lists"
        />
        <Route
          element={<Navigate replace to="/settings/task_lists/new" />}
          path="task_lists/new"
        />
        <Route
          element={
            <RedirectWithParameters to="/settings/task_lists/:id/edit" />
          }
          path="task_lists/:id/edit"
        />
        <Route
          element={<RedirectWithParameters to="/settings/task_lists/:id" />}
          path="task_lists/:id"
        />

        <Route
          element={<Navigate replace to="/settings/retrospectives/edit" />}
          path="organizations/retrospectives/configure"
        />

        {retroTemplates && (
          <>
            <Route
              element={
                <Navigate replace to="/settings/retrospectives/templates" />
              }
              path="organizations/retrospectives/configure"
            />
            <Route
              element={<RetrospectiveTemplateGuide />}
              path="/settings/retrospectives/templates/new"
            />
            <Route
              element={<RetrospectiveTemplateGuide />}
              path="/settings/retrospectives/templates/:retrospectiveTemplateId/edit"
            />
            <Route
              element={<RetrospectiveTemplateGuide isCopy={true} />}
              path="/settings/retrospectives/templates/:retrospectiveTemplateId/copy"
            />
            <Route
              element={<RetrospectiveTemplateView />}
              path="/settings/retrospectives/templates/:retrospectiveTemplateId"
            />
          </>
        )}

        <Route
          element={<Navigate replace to="/incidents/maintenances" />}
          path="scheduled_maintenances"
        />
        <Route
          element={<Navigate replace to="/incidents/maintenances/new" />}
          path="scheduled_maintenances/new"
        />
        <Route
          element={<ScheduledMaintenanceForm />}
          path="/incidents/maintenances/new"
        />
        <Route
          element={<ScheduledMaintenanceForm />}
          path="/incidents/maintenances/:scheduledMaintenanceId/edit"
        />
        <Route
          element={
            <RedirectWithParameters to="/incidents/maintenances/:id/edit" />
          }
          path="scheduled_maintenances/:id/edit"
        />

        <Route
          element={<Navigate replace to="/status_pages" />}
          path="/organizations/nunc"
        />
        <Route
          element={<Navigate replace to="/status_pages/new" />}
          path="/organizations/nunc/new"
        />
        <Route
          element={<RedirectWithParameters to="/status_pages/:id/edit" />}
          path="/organizations/nunc/:id/edit"
        />
        <Route
          element={
            <RedirectWithParameters to="/status_pages/:id/subscribers" />
          }
          path="/organizations/nunc/:id/subscribers"
        />

        <Route
          element={<Navigate replace to="/settings/integrations" />}
          path="organizations/integrations"
        />

        <Route
          element={<RedirectWithParameters to="/settings/integrations/:slug" />}
          path="organizations/integrations/:slug"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId" />
          }
          path="organizations/integrations/:slug/connections/:connectionId"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/projects/new" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/projects/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/projects/:projectId/:configId/edit" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/projects/:projectId/:configId/edit"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/projects/:projectId/field_mapping/new" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/projects/:projectId/field_mapping/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/projects/:projectId/field_mapping/:mapId/edit" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/projects/:projectId/field_mapping/:mapId/edit"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/command_extensions/new" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/command_extensions/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/command_extensions/:commandExtensionId/edit" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/command_extensions/:commandExtensionId/edit"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/:slug/connections/:connectionId/*" />
          }
          path="organizations/integrations/:slug/connections/:connectionId/*"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/webhooks" />
          }
          path="organizations/webhooks"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/webhooks/new" />
          }
          path="organizations/webhooks/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/integrations/webhooks/:connectionId/edit" />
          }
          path="organizations/webhooks/:connectionId/edit"
        />

        <Route
          element={<Navigate replace to="/catalog/services" />}
          path="/services"
        />

        <Route
          element={<Navigate replace to="/catalog/services/new" />}
          path="/services/new"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/services/:id/edit" />}
          path="/services/:id/edit"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/services/:id" />}
          path="/services/:id"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/services/:id/:*" />}
          path="/services/:id/*"
        />

        <Route
          element={
            <Navigate replace to="/catalog/services/catalog_settings/new" />
          }
          path="/services/catalog_settings/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/services/catalog_settings/:id/edit" />
          }
          path="/services/catalog_settings/:id/edit"
        />

        <Route
          element={<Navigate replace to="/catalog/services/catalog_settings" />}
          path="/services/catalog_settings"
        />

        <Route
          element={<Navigate replace to="/catalog/functionalities" />}
          path="functionalities"
        />

        <Route
          element={<Navigate replace to="/catalog/functionalities/new" />}
          path="functionalities/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/functionalities/:id/edit" />
          }
          path="functionalities/:id/edit"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/functionalities/:id" />}
          path="functionalities/:id"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/functionalities/:id/*" />
          }
          path="functionalities/:id/*"
        />

        <Route
          element={<Navigate replace to="/catalog/environments" />}
          path="environments"
        />

        <Route
          element={<Navigate replace to="/catalog/environments/new" />}
          path="environments/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/environments/:id/edit" />
          }
          path="environments/:id/edit"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/environments/:id" />}
          path="environments/:id"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/environments/:id/*" />}
          path="/environments/:id/*"
        />

        <Route
          element={<Navigate replace to="/catalog/change_events" />}
          path="change_events"
        />

        <Route
          element={<Navigate replace to="/catalog/change_events/new" />}
          path="change_events/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/change_events/:id/edit" />
          }
          path="change_events/:id/edit"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/change_events/:id" />}
          path="change_events/:id"
        />

        <Route
          element={<Navigate replace to="/catalog/checklists" />}
          path="checklists"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/checklists/new" />}
          path="checklists/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/catalog/checklists/create_new" />
          }
          path="checklists/create_new"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/checklists/:id/edit" />}
          path="checklists/:id/edit"
        />

        <Route
          element={<RedirectWithParameters to="/catalog/checklists/:id" />}
          path="checklists/:id"
        />

        <Route
          element={<Navigate replace to="/settings/priorities/new" />}
          path="organizations/priorities/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/priorities/:id/edit" />
          }
          path="organizations/priorities/:id/edit"
        />

        <Route
          element={<Navigate replace to="/settings/severities/new" />}
          path="organizations/severities/new"
        />

        <Route
          element={
            <RedirectWithParameters to="/settings/severities/:id/edit" />
          }
          path="organizations/severities/:id/edit"
        />
        {!isCurrentUserLoading && (isUserOwner || isUserStaffSession) ? (
          <Route path="onboarding" element={<OnboardingV3 />} />
        ) : (
          !isCurrentUserLoading && (
            <Route path="onboarding" element={<Navigate to="/overview" />} />
          )
        )}
      </Route>

      {isUserOwner || isUserStaffSession ? (
        <Route path="onboarding" element={<OnboardingV3 />} />
      ) : (
        <Route path="onboarding" element={<Navigate to="/overview" />} />
      )}
      <Route element={<FiredUpDashboard />} path="overview" />
      <Route path="v1/incidents/:id/*" element={<CCV1Redirect />} />
      <Route element={<EnvironmentsIndexV2 />} path="environments" />
      <Route element={<RunbooksIndexV2 />} path="runbooks" />
      <Route element={<TeamsIndex />} path="teams" />
      <Route element={<Page404 />} path="*" />
    </Routes>
  );
};

const RoutePack = () => {
  return (
    <SPAWrapper>
      <Router>
        <ScrollToTop />
        <SessionWrapper>
          <Suspense fallback={<Loading />}>
            <SessionReplayWrapper>
              <Routes>
                <Route path="/" element={<LoginPage />} />
                <Route path="/sessions/new" element={<LoginPage />} />
                <Route path="/sessions/sso/new" element={<LoginPage />} />
                <Route
                  path="/registrations/new"
                  element={<SignalsRegistrationNew />}
                />
                <Route
                  path="/registrations/:id/complete"
                  element={<SignalsRegistrationComplete />}
                />
                <Route
                  path="/org/:orgId/onboarding/signals"
                  element={<SignalsOnboarding />}
                />
                <Route
                  path="/org/:orgId/onboarding/signals/alert"
                  element={<SignalsOnboardingAlert />}
                />
                <Route
                  element={
                    <MultiOrgRoutes
                      Component={
                        <FullPageLayout>
                          <LayoutRoutes />
                        </FullPageLayout>
                      }
                    />
                  }
                  path="*"
                />
              </Routes>
            </SessionReplayWrapper>
          </Suspense>
        </SessionWrapper>
      </Router>
    </SPAWrapper>
  );
};

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('global');

  const { currentUserFlagIdentity } = window;

  // For any template that doesn't have a id='global' just return early
  if (!node) {
    return;
  }

  const root = ReactDOMClient.createRoot(node);

  (async () => {
    const signedOutUser = {
      key: 'signed out user',
      name: 'signed out user',
      email: 'signed out user',
      account_id: null,
      organizations: [],
      staff_session: false,
      tags: ['signed out user'],
    };
    const user = currentUserFlagIdentity || signedOutUser;
    user.kind = 'user';

    const LDProvider = await asyncWithLDProvider({
      clientSideID: window.launchDarklyAPIKey,
      context: user,
    });
    root.render(
      <LDProvider>
        <RoutePack />
      </LDProvider>,
    );
  })();
});
